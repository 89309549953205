menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
  list-style-position: inside;
}

/* Footer */
.footer-menu > li {
  margin-bottom: 0.75rem;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 310px; /* Footer height */
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 310px;
  background-size: 1295px 508px;
  background-image: url(./components/layout/footer/footer.png);
  background-position: bottom -200px center;
  background-repeat: no-repeat;
}

.grid {
  display: grid;
}

.gap-4 {
  grid-column-gap: 1rem;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.aspect-ratio-container-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 600px) {
  .aspect-ratio-container {
    position: relative;
    overflow: hidden;
    padding-top: 100%;
  }

  .aspect-ratio-container-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  }
}

@media (min-width: 768px) {
  .aspect-ratio-container-inner {
    padding: 3rem;
  }
}

@media (min-width: 900px) {
  .aspect-ratio-container-inner {
    padding: 5rem;
  }
}

@media (min-width: 1200px) {
  .aspect-ratio-container-inner {
    padding: 7rem;
  }
}
